

import { defineComponent, ref, watch, computed, toRefs } from 'vue';
import { ElMessageBox, ElMessage } from 'element-plus';
import {
  ResponseError,
  DEFAULT_PAGE_SIZE,
  DELETED_SUCCESSFULLY_TEXT,
  MAX_PER_PAGE, INCLUDE_SUCCESSFULLY_TEXT,
  EXCLUDE_SUCCESSFULLY_TEXT,
  VERIFYING_SUCCESSFULLY_TEXT
} from '@/services/api';
import {
  useDeleteSuggestion, useSuggestions, useExclude,
  useInclude, useVerifying, useExcludeAll
} from '@/composables/api';
import useFormErrors from '@/utils/validate';
import { formatLocalTime } from '@/utils/format-time';
import { useRoute } from 'vue-router';
import { SuggestionStatus } from '@/interfaces';

export default defineComponent({
  props: {
    type: {
      type: String,
      default: ''
    },
    keyword: {
      type: String,
      default: ''
    },
    eventStatus: {
      type: String,
      default: ''
    },
    // eslint-disable-next-line vue/require-default-prop
    refetchSuggestionCount: {
      type: Function
    }
  },
  emits: ['dataCount'],
  setup(props, ctx) {
    const { formErrors } = useFormErrors();
    const page = ref(1);
    const eventId = useRoute().params.id as string;
    const route = useRoute();
    const queryType = computed(() => route.query.type);
    const querySuggestionsStatus = computed(() => route.query.status);
    const { eventStatus } = toRefs(props);
    const statusIndex = ref(undefined);
    const { isLoading: isDeleting, mutate: deleteSuggestion } = useDeleteSuggestion();
    const includeIndex = ref(undefined);
    const { isLoading: includeLoading, mutate: include } = useInclude();
    const excludeIndex = ref(undefined);
    const { isLoading: excludeLoading, mutate: exclude } = useExclude();
    const verifyingIndex = ref(undefined);
    const { isLoading: verifyingLoading, mutate: verifying } = useVerifying();
    const { isLoading: inExcludeAllLoading, mutate: excludeAll } = useExcludeAll();

    const keyword = ref();
    const status = ref(querySuggestionsStatus.value as SuggestionStatus);
    const pageSize = ref(DEFAULT_PAGE_SIZE);

    const { data, isLoading, isFetching, refetch } = useSuggestions(
      { eventId },
      {
        page,
        pageSize,
        keyword,
        type: props.type,
        status
      }
    );

    const setPageSize = () => {
      if (querySuggestionsStatus.value === 'include') {
        if (eventStatus.value === 'voting' || eventStatus.value === 'finished') {
          pageSize.value = MAX_PER_PAGE;
          return;
        }
      }
      pageSize.value = DEFAULT_PAGE_SIZE;
    };

    watch(querySuggestionsStatus, (newValue) => {
      setPageSize();
      if (!newValue) return;
      page.value = 1;
      status.value = newValue as SuggestionStatus;
    }, { immediate: true });

    // watch(() => route.query, (query) => {
    //   if (Object.keys(query).length < 2) return;
    //   console.log('query', query);
    //   refetch.value();
    // }, { immediate: true });

    const fetchDelete = (suggestionId: string, index: number) => {
      ElMessageBox.confirm('是否確認要刪除?', '警告', {
        confirmButtonText: '刪除',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          statusIndex.value = index;
          deleteSuggestion(
            { suggestionId },
            {
              onSuccess() {
                refetch.value();
                ElMessage.success(DELETED_SUCCESSFULLY_TEXT);
                props.refetchSuggestionCount();
              },
              onError(error: any) {
                ElMessage({
                  message: error.response?.data.message,
                  type: 'error'
                });
              }
            }
          );
        })
        .catch();
    };

    watch(() => props.keyword, (newValue) => {
      keyword.value = newValue;
    });

    const fetchInclude = (suggestionId: string, index: number) => {
      includeIndex.value = index;
      include(
        { suggestionId },
        {
          onSuccess() {
            refetch.value();
            ElMessage.success(INCLUDE_SUCCESSFULLY_TEXT);
            props.refetchSuggestionCount();
          },
          onError(error: any) {
            ElMessage({
              message: error.response?.data.message,
              type: 'error'
            });
          }
        }
      );
    };
    const fetchExclude = (suggestionId: string, index: number) => {
      excludeIndex.value = index;
      exclude(
        { suggestionId },
        {
          onSuccess() {
            refetch.value();
            ElMessage.success(EXCLUDE_SUCCESSFULLY_TEXT);
            props.refetchSuggestionCount();
          },
          onError(error: any) {
            ElMessage({
              message: error.response?.data.message,
              type: 'error'
            });
          }
        }
      );
    };
    const fetchVerifying = (suggestionId: string, index: number) => {
      verifyingIndex.value = index;
      verifying(
        { suggestionId },
        {
          onSuccess() {
            refetch.value();
            ElMessage.success(VERIFYING_SUCCESSFULLY_TEXT);
            props.refetchSuggestionCount();
          },
          onError(error: any) {
            ElMessage({
              message: error.response?.data.message,
              type: 'error'
            });
          }
        }
      );
    };
    const fetchExcludeAll = () => {
      const suggestionIds = data?.value?.data.map((item) => item.id);

      excludeAll(
        { data: { suggestionIds } },
        {
          onSuccess() {
            refetch.value();
            ElMessage.success(EXCLUDE_SUCCESSFULLY_TEXT);
            props.refetchSuggestionCount();
          },
          onError(error: any) {
            ElMessage({
              message: error.response?.data.message,
              type: 'error'
            });
          }
        }
      );
    };
    const onExcludeAllDialog = () => {
      ElMessageBox.confirm('是否確認要全部淘汰?', '警告', {
        confirmButtonText: '全部淘汰',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          fetchExcludeAll();
        })
        .catch();
    };

    const showLastColumn = () => {
      if (eventStatus.value === 'verifying') return true;
      if (querySuggestionsStatus.value === 'include') return true;
      return false;
    };

    watch(() => data.value, () => {
      ctx.emit('dataCount', data.value?.data ? data.value?.meta.total : 0);
    }, { immediate: true });

    // watch(() => status.value, () => {
    //   setPageSize();
    // }, { immediate: true });

    const getBarColor = (rank: number) => {
      switch (rank) {
        case 1:
          return '#d73841';
        case 2:
          return '#7fb86a';
        case 3:
          return '#0169cb';
        default:
          return '#aaaaaa';
      }
    };

    const showMedalImage = (rank: number) => {
      const ranks = [1, 2, 3];
      return ranks.includes(rank);
    };

    return {
      data,
      page,
      pageSize,
      isLoading,
      isFetching,
      isDeleting,
      includeLoading,
      excludeLoading,
      verifyingLoading,
      statusIndex,
      formatLocalTime,
      querySuggestionsStatus,
      queryType,
      fetchInclude,
      fetchExclude,
      fetchDelete,
      fetchVerifying,
      fetchExcludeAll,
      onExcludeAllDialog,
      showLastColumn,
      getBarColor,
      includeIndex,
      excludeIndex,
      verifyingIndex,
      refetch,
      setPageSize,
      showMedalImage,
      MAX_PER_PAGE
    };
  }
});
