
import { defineComponent, ref, toRefs, watch, computed, unref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useSuggestionCount, useCreateSuggestion } from '@/composables/api';
import SearchBox from '@/components/keyword-searching/Index.vue';
import { useFormErrors } from '@/composables/useFormErrors';
import { ElMessage, ElForm } from 'element-plus';
import { getThousandSeparator } from '@/utils/render';
import SuggestionTable from './SuggestionTable.vue';
import { ResponseError, CREATED_SUCCESSFULLY_TEXT } from '@/services/api';
import {
  EventSuggestionStatus,
  SuggestionStatus,
  PartialSuggestions,
  SuggestionType
} from '@/interfaces';
import { normalizeRequestData } from '@/utils/normalization';
import { formatLocalTime } from '@/utils/format-time';
const DEFAULT_FORM_VALUES: PartialSuggestions = {
  type: SuggestionType.PRODUCT,
  content: ''
};

const RULES = {
  type: [
    {
      required: true
    }
  ],
  content: [
    {
      required: true
    }
  ]
};

export default defineComponent({
  props: {
    /**
     * 活動的狀態
     */
    isVerifying: {
      type: Boolean,
      default: false
    }
  },
  components: {
    SearchBox,
    SuggestionTable
  },
  setup(props) {
    const { isVerifying } = toRefs(props);
    const eventId = useRoute().params.id as string;
    const router = useRouter();
    const route = useRoute();
    const queryStatus = computed(() => route.query.status);
    const queryType = computed(() => route.query.type);
    const querySearch = computed(() => route.query.search as string);
    const keyword = ref(querySearch.value || '');
    const formRef = ref<typeof ElForm>();
    const tableKey = ref(1);
    const activeTab = ref('product');
    const { data, isLoading, isFetching, refetch } = useSuggestionCount(
      { eventId },
      { keyword });
    const { isLoading: isCreating, mutate: create } = useCreateSuggestion();
    const { formErrors, bindFormItemError } = useFormErrors();
    const showDialog = ref(false);
    const suggestionCount = ref({
      verifying: 0,
      include: 0,
      exclude: 0
    });

    const formValues = ref({ ...DEFAULT_FORM_VALUES });

    const searchKeyword = (_keyword: string) => {
      keyword.value = _keyword;
    };

    const activatedCard = ref(isVerifying.value ? 0 : 1);
    const currentStatus = ref(isVerifying.value ? 'verifying' : 'include');
    const onClickCard = (index, status) => {
      activatedCard.value = index;
      currentStatus.value = status;
    };
    const productCount = ref(0);
    const contentCount = ref(0);
    const actorCount = ref(0);

    watch(() => [activeTab.value, currentStatus.value, keyword.value], (query) => {
      if (query.length < 2) return;
      router.replace({ query: { ...(route.query), type: query[0], status: query[1], search: query[2] } });
    }, { immediate: true });

    const getSuggestionStatusName = (status: string) => {
      switch (status) {
        case 'verifying':
          return '待審核';
        case 'include':
          return '已入選';
        case 'exclude':
          return '已淘汰';
        default:
          break;
      }
    };

    const getSuggestionStatusCount = (suggestionStatusCount) => {
      suggestionStatusCount = {
        verifying: suggestionStatusCount?.verifying | 0,
        include: suggestionStatusCount?.include | 0,
        exclude: suggestionStatusCount?.exclude | 0
      };
      return suggestionStatusCount;
    };

    const fetchAddSuggestion = () => {
      formErrors.value = {};
      const form = unref(formRef);
      form && form.validate((valid: boolean) => {
        if (valid) {
          create(
            {
              eventId,
              data: normalizeRequestData(formValues.value)
            },
            {
              onSuccess() {
                ElMessage.success(CREATED_SUCCESSFULLY_TEXT);
                formValues.value = DEFAULT_FORM_VALUES;
                showDialog.value = false;
                refetch.value();
                tableKey.value++;
              },
              onError(error: ResponseError) {
                ElMessage.error(error.response?.data.message);
                formErrors.value = error.response?.data.message;
                showDialog.value = false;
              }
            }
          );
        }
      });
    };
    const OpenAddSuggestion = () => {
      showDialog.value = true;
    };
    const closeDialog = () => {
      formValues.value = { ...DEFAULT_FORM_VALUES };
    };

    const getCurrentCount = () => {
      switch (queryType.value) {
        case SuggestionType.PRODUCT:
          return productCount.value;
        case SuggestionType.CONTENT:
          return contentCount.value;
        case SuggestionType.ACTOR:
          return actorCount.value;
        default:
          break;
      }
    };

    const getRequestCount = (currentCount: number) => {
      const requestCount = 10 - currentCount;
      return requestCount <= 0 ? 0 : requestCount;
    };

    const showRedDot = (count: number | string) =>
      data.value?.data?.status === 'verifying' && currentStatus.value === 'verifying' && +count > 0;

    const isIncludeOverflow = (suggestionStatusCount: {
      counts: number
      suggestionStatus: SuggestionStatus
    }) => {
      if (suggestionStatusCount.suggestionStatus !== 'include') return;
      return suggestionStatusCount.counts > 30;
    };

    return {
      querySearch,
      data,
      formValues,
      formRef,
      RULES,
      bindFormItemError,
      searchKeyword,
      onClickCard,
      activatedCard,
      activeTab,
      suggestionCount,
      getSuggestionStatusName,
      getThousandSeparator,
      currentStatus,
      keyword,
      fetchAddSuggestion,
      EventSuggestionStatus,
      SuggestionStatus,
      queryStatus,
      showDialog,
      closeDialog,
      OpenAddSuggestion,
      productCount,
      contentCount,
      actorCount,
      getCurrentCount,
      getRequestCount,
      showRedDot,
      tableKey,
      getSuggestionStatusCount,
      refetch,
      isIncludeOverflow,
      formatLocalTime,
      isCreating
    };
  }
});
